import React from 'react';
import Carousel from 'react-material-ui-carousel';
import { Button, Divider } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Para0 from './img/code/HD-wallpaper-code-programming-monitor-hacker.jpg';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

import AOS from 'aos';
import Joseph from "./img/joseph.jpg"
import Kofi from "./img/kofi.jpg"
import Osei from "./img/osei.jpg"
import Grid from '@material-ui/core/Grid';
import TiltPhaseSix from './TiltPhaseSix';
import User from './img/logo/discussion-animate.svg';



import Particles from 'react-tsparticles'
import "aos/dist/aos.css";
import Cvmain from "./cv_joseph.pdf"


const options = {
    max: 5,
    perspective: 700,
    scale: 1.05,
}

const useStyles = makeStyles((theme) => ({

    large:{
        
        [theme.breakpoints.up('xs')]: {
         width: theme.spacing(26),
         height: theme.spacing(26),
            marginLeft:'20%',
            marginBottom:"10px"
         },
        [theme.breakpoints.up('sm')]: {
         width: theme.spacing(26),
         height: theme.spacing(26),
            marginLeft:'13%',
            marginBottom:"10px"
         }
    
   },
  

    larger: {
        [theme.breakpoints.down('sm')]: {
            color: "white",
            fontWeight: "bolder"

        }, [theme.breakpoints.up('lg')]: {
            display: "none"
        }

    },
    smaller: {
        [theme.breakpoints.down('sm')]: {
            display: "none",


        }, [theme.breakpoints.up('lg')]: {
            color: "white"
        }

    },

    image: {
        position: 'relative',
        height: 300,
        [theme.breakpoints.down('xs')]: {
            width: '100% !important', // Overrides inline-style
            height: 100,
        },
        '&:hover, &$focusVisible': {
            zIndex: 0,
            '& $imageBackdrop': {
                opacity: 0.15,
            },
            '& $imageMarked': {
                opacity: 0,
            },
            '& $imageTitle': {
                border: '4px solid currentColor',
            },
        },
    },
    focusVisible: {},
    imageButton: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.common.white,
    },
    imageSrc: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundSize: 'cover',
        backgroundPosition: 'center 40%',
    },
    imageBackdrop: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: theme.palette.common.black,
        opacity: 0.4,
        transition: theme.transitions.create('opacity'),
    },
    imageTitle: {
        position: 'relative',
        padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`,
    },
    imageMarked: {
        height: 3,
        width: 18,
        backgroundColor: theme.palette.common.white,
        position: 'absolute',
        bottom: -2,
        left: 'calc(50% - 9px)',
        transition: theme.transitions.create('opacity'),
    },

    root: {

        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    imageList: {
        width: '100%',
        height: '100%',
    },
    icon: {
        color: 'rgba(255, 255, 255, 0.54)',
    },



    caro: {

        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        [theme.breakpoints.up('xs')]: {
            height: 240,
            paddingTop: '15%'
        },
        [theme.breakpoints.up('sm')]: {
            height: 550,
            paddingTop: '15%'

        }
    }
}));



function Example(props) {
    AOS.init({
        // initialise with other settings
        duration: 700
    });

    var items = [
        {
            name: "Scientific Computation",
            image: Para0,
            description: "I love scientific computation"
        }
    ]


    const classes = useStyles();







    return (

        <div>










            <Carousel swipe={true} indicators={false} touch={true} animation={""} >
                {
                    items.map((item, i) => <Item key={i} item={item} />)
                }
            </Carousel>




            <div>









                <div style={{ backgroundSize: "cover", paddingTop: 30, paddingBottom: 30 }}>


                    <Container maxWidth="md" style={{ textAlign: "left", backfroundColor: "rgba(255,255,255,0.1)" }}>
                    <h2 className="MuiTypography-h5" style={{textAlign:"Center", marginBottom:"-40px"}} >
                        <b>What SIS Revolution?</b>
                        <div align="center">
                            
                            <hr data-aos="fade-left" style={{textAlign:"center", width:"20%", marginTop:10, marginBottom:40,backgroundImage:"linear-gradient(300deg, #17dddd 0%,  #1760d3 100%)", border:"none", height:"4px"}}/></div>

                       
            </h2>
            <br/>
            <div style={{textAlign:"center"}}>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/cZzMJyGNiMI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>

<br/>
<br/>
                        <div data-aos="fade-up">

                        <p style={{textAlign:"justify"}}>  
                        Schools' Information System (SIS) Revolution is a centralized school database management system specially built to 
digitize the activities of schools, with the vision of also decentralizing our activities for 
tertiary, secondary schools and others upon request.
<br/>
<br/>
Information Technology has become very essential, as it has become the main tool for 
solving major problems we face daily. The relevance of SIS Revolution is dependent on our 
ability to make life easier for schools by providing reliable system for them to manage their 
financial records, student records, staff records, easy access to parents etc. 
Managing an educational system requires careful planning and time management. Today, 
a school has evolved into a complex institution with multiple campuses, manifold 
departments, several sections and a very large number of students.
<br/>
<br/>
For schools moving from a traditional paper-based data management system to a digital, 
automated system is a critical need of the hour. This school database management system in 
it's simplest form uses a digital tracking system to maintain the record of all the students and 
staff. However.
<br/>
<br/>

SIS Revolution is aimed to operate in Ghana, as we have found solutions to major problems 
faced in schools in this domain. Notwithstanding the aforementioned statement, our scope 
of operation will be extended over to other countries in Africa as well.

</p> 

                        </div>
<br/>
<br/>
<Divider/>
<br/>

<Grid container spacing={4}>

<Grid item lg={6} sm={12} xs={12} md={6}>
<h2 className="MuiTypography-h5" style={{textAlign:"Center", marginBottom:"-40px"}} data-aos="fade-in">
                        <b>Our Vission</b>
                        <div align="center"><hr data-aos="fade-left" style={{textAlign:"center", width:"20%", marginTop:10, marginBottom:40,backgroundImage:"linear-gradient(300deg, #17dddd 0%,  #1760d3 100%)", border:"none", height:"4px"}}/></div>

                   
            </h2>
            <br/>
<p style={{color:"#1760d3"}}>
To become the best resort for school management in Africa by providing reliable web based and mobile applications for schools to manage their database and interact with 
clients and potential clients. We endeavor to improve SIS Revolution with all emerging 
technologies especially AI to make it the best ever for schools. 
</p>
</Grid>
<Grid item lg={6} sm={12} xs={12} md={6}>
<h2 className="MuiTypography-h5" style={{textAlign:"Center", marginBottom:"-40px"}} data-aos="fade-in">
                        <b>Our Mission</b>
                        <div align="center"><hr data-aos="fade-left" style={{textAlign:"center", width:"20%", marginTop:10, marginBottom:40,backgroundImage:"linear-gradient(300deg, #17dddd 0%,  #1760d3 100%)", border:"none", height:"4px"}}/></div>

                       
            </h2>
            <br/>
           
<p style={{color:"#1760d3"}}>
<p style={{color:"#1760d3"}}>Our Mission are as follows;</p>
   

        <li>Provides schools' administrative system. </li>
        <li>Provides teachers’ mobile applications</li>
        <li>Provides parents’ mobile applications </li>

  
 
</p>
</Grid>

</Grid>


{/* <br/>
<br/> */}
<Divider/>
<br/>

                    {/* <h2 className="MuiTypography-h5" style={{textAlign:"Center", marginBottom:"-40px"}} data-aos="fade-in">
                        <b>Our Team</b>
                        <div align="center"><hr data-aos="fade-left" style={{textAlign:"center", width:"12%", marginTop:10, marginBottom:40,backgroundImage:"linear-gradient(300deg, #17dddd 0%,  #1760d3 100%)", border:"none", height:"4px"}}/></div>

                       
            </h2>
            <br/>

            <Grid spacing={4} container>



            <Grid item lg={4} xs={12} sm={3} md={3} style={{textAlign:'center', marginBottom:15}} data-aos={"fade-up"}> 
                
                <Avatar alt="Remy Sharp" src={Joseph} className={classes.large} />
    <Typography gutterBottom variant="h6" component="h2" data-aos={"fade-up"}>
             Joseph Ngissah
              </Typography>
    <p style={{marginTop:"-12px", color:"grey", textAlign:"center"}} data-aos={"fade-up"}>
                       Co-Founder & Lead Developer
    </p>
    
            
             </Grid> 


            <Grid item lg={4} xs={12} sm={3} md={3} style={{textAlign:'center', marginBottom:15}} data-aos={"fade-up"}> 
                
                <Avatar alt="Remy Sharp" src={Osei} className={classes.large} />
    <Typography gutterBottom variant="h6" component="h2" data-aos={"fade-up"}>
             Osei Kwadwo Benjamin
              </Typography>
    <p style={{marginTop:"-12px", color:"grey", textAlign:"center"}} data-aos={"fade-up"}>
                       Co-Founder
    </p>
    
            
             </Grid> 


            <Grid item lg={4} xs={12} sm={3} md={3} style={{textAlign:'center', marginBottom:15}} data-aos={"fade-up"}> 
                
                <Avatar alt="Remy Sharp" src={Kofi}  className={classes.large} />
    <Typography gutterBottom variant="h6" component="h2" data-aos={"fade-up"}>
             Kyei Baffour 
              </Typography>
    <p style={{marginTop:"-12px", color:"grey", textAlign:"center"}} data-aos={"fade-up"}>
                       Frontend Developer
    </p>
    
            
             </Grid> 

            </Grid> */}
                    </Container>



            <br/>
            <br/>
            <br/>

                </div>





            </div>




            {/* <br />
            <br />
            <br />
            <br /> */}

        </div>


    )
}



function Item(props)



{
    const classes=useStyles()
    return (
        
        
<div>

<div style={{position:"absolute", zIndex:1, width:"100%"}}>
<Grid container spacing={5} >
<Grid item  xs={12} sm={12} lg={6} md={6} style={{ marginTop:"0%"}}>

                       
                        <div id="App"  data-aos={"flip-right"}   style={{ color: "white", fontSize: "33px", marginTop: 10, position: "relative", zIndex: 1 }}>
                            <TiltPhaseSix
                                options={{}}

                            >
                                <TiltPhaseSix
                                    options={options}
                                >
                                    <img src={User} className="imager" alt="" />
                                </TiltPhaseSix>
                            </TiltPhaseSix>

                           

                           

                            {/* <Button style={{ backgroundImage: "linear-gradient(315deg, #ffb700 0%,  #1760d3 74%)", color: "white", zIndex: 1 }} className={classes.smaller}>
                                Download CV
                            </Button> */}
                        </div>
                        

</Grid>

<Grid item  xs={12} sm={12} lg={5} md={6}  >

<div style={{ position: "relative", zIndex: 1, marginTop: "27%",}}>
<div align="center"><hr data-aos="fade-right" style={{textAlign:"center", width:"90%", marginTop:"-80px", marginBottom:10,backgroundImage:"linear-gradient(300deg, #17dddd 0%,  #1760d3 100%)", border:"none", height:"4px"}}/></div>

<h1 className={classes.smaller} style={{color:"white", fontWeight:"bolder"}}>
    GET TO KNOW MORE <br/>
  <small style={{fontSize:"18px", marginTop:"-25px"}}>Get To Know What We Stand For</small>

</h1>
<p className={classes.larger} style={{color:"white", fontWeight:"bolder"}}>
  <small style={{fontSize:"16px", marginTop:"-25px"}}>Get To Know What We Stand For</small>

</p>
<div align="center"><hr data-aos="fade-left" style={{textAlign:"center", width:"90%", marginTop:10, marginBottom:40,backgroundImage:"linear-gradient(300deg, #17dddd 0%,  #1760d3 100%)", border:"none", height:"4px"}}/></div>


                     
</div>
</Grid>

</Grid>


          
</div>






<Particles className="particle" style={{position:"",zIndex:222, height:"100%", width:"200%", backgroundColor:"red" }}
        id="tsparticle"

        options={{
          background: {
            color: {
              value: "",
            },
        
          },

          fpsLimit: 30,
          interactivity: {
            detectsOn: "canvas",
            events: {
              onClick: {
                enable: true,
                mode: "push",
              },
              onHover: {
                enable: false,
                mode: "repulse",
              },
              resize: true,
            },
            modes: {
              bubble: {
                distance: 400,
                duration: 0.5,
                opacity: 0.8,
                size: 40,
              },
              push: {
                quantity: 10,
              },
              repulse: {
                distance: 200,
                duration: 0.4,
              },
            },
          },
            particles: {
                color: {
                    value: ["#1760d3", "#1760d3", "#1760d3"]
                },
                links: {
                    color: "#1760d3",
                    distance: 150,
                    enable: true,
                    opacity: 0.2,
                    width: 0.8,
                },
                collisions: {
                    enable: false,
                },
                move: {
                    direction: "none",
                    enable: true,
                    outMode: "bounce",
                    random: true,
                    speed: 3,
                    straight: false,
                },
                number: {
                    density: {
                        enable: true,
                        value_area: 900,
                    },
                    value: 100,
                },
                opacity: {
                    value: 0.2,
                },
                shape: {
                    type: "circle",
                },
                size: {
                    random: true,
                    value: 5,
                },
            },
          detectRetina: true,
        }}


      />

   </div>  

    )
}

export default Example



import React from 'react';
import { useEffect } from 'react';
import Carousel from 'react-material-ui-carousel';
import {Button, Divider} from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Green from './img/sustainable.jpg';
import Energy from './img/energy.jpg';
import Use2 from './img/use2.jpg';
import User2 from './img/port/Component33.png';

import Turn from './img/code/fast.png';
import Topcode from './img/code/top.png';
import Friend from './img/code/friendly.png';
import Seo from './img/code/SEO.png';
import Security from './img/code/security.png';
import User from './img/logo/img1.svg';
import Web from './img/logo/FriendlyandResponsiveLogos.png';
import Mobile from './img/logo/mobile_app.png';
import Graphic from './img/logo/spage.png';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import Para0 from './img/logo/183850_raven-hd-wallpapers_1920x1200_h.jpg';
import Avatar from '@material-ui/core/Avatar';
import TiltPhaseSix from './TiltPhaseSix';
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Cvmain from "./cv_joseph.pdf"
import Typography from '@material-ui/core/Typography';
import AOS from 'aos';
import Email from '@material-ui/icons/Email';
import Call from '@material-ui/icons/Call';
import LinkedIn from '@material-ui/icons/LinkedIn';
import Facebook from '@material-ui/icons/Facebook';
import Instagram from '@material-ui/icons/Instagram';
import Twitter from '@material-ui/icons/Twitter';
import GitHub from '@material-ui/icons/GitHub';
import Particles from 'react-tsparticles' 
import Portimage from './portimage' 
import Image0 from './img/image0.gif' 
import Image00 from './img/logo/img2.svg' 
import Image01 from './img/logo/img3.svg' 
import Image02 from './img/logo/img4.svg' 
import Parents from './img/logo/parentsp.svg';
import Vid from './img/sis.mp4' 
import "aos/dist/aos.css";

import "./index.css";
import { Parallax } from "react-parallax";

import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Carousel_ from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// import ListItemAvatar from '@mui/material/ListItemAvatar';

import AlldoneIcon from '@material-ui/icons/DoneAll';

import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';

import  {
  Link
} from 'react-router-dom';


const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 6
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 4
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};
const useStyles=makeStyles((theme)=>({
   
  large: {
       [theme.breakpoints.down('sm')]: {
      width: theme.spacing(20),
    height: theme.spacing(20),
          marginLeft: '20%'

    }, [theme.breakpoints.up('lg')]: {
       width: theme.spacing(43),
    height: theme.spacing(43),
           marginLeft:'24%',
        marginTop:'20%'
    }
   
    },

     larger: {
         [theme.breakpoints.down('sm')]: {
             color: "white",
             fontWeight:"bolder"

    }, [theme.breakpoints.up('lg')]: {
             display: "none"
    }
   
    },
      smaller: {
         [theme.breakpoints.down('sm')]: {
              display: "none",
             

    }, [theme.breakpoints.up('lg')]: {
              color: "white"
    }
   
    },

    image: {
    position: 'relative',
    height: 300,
    [theme.breakpoints.down('xs')]: {
      width: '100% !important', // Overrides inline-style
      height: 100,
    },
    '&:hover, &$focusVisible': {
      zIndex: 0,
      '& $imageBackdrop': {
        opacity: 0.15,
      },
      '& $imageMarked': {
        opacity: 0,
      },
      '& $imageTitle': {
        border: '4px solid currentColor',
      },
    },
  },
  focusVisible: {},
  imageButton: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
  },
  imageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create('opacity'),
  },
  imageTitle: {
    position: 'relative',
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
  },

    root: {
      
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  imageList: {
    width: '100%',
    height: '100%',
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
        
    caro:{ 
        
        backgroundRepeat: 'no-repeat', 
        backgroundSize:'cover',
        backgroundPosition:'center',  
        [theme.breakpoints.up('xs')]: {
        height:240,
            paddingTop: '15%'
    },   
      [theme.breakpoints.up('sm')]: {
      height:550,
          paddingTop: '15%'
         
    }
    }
}));


const options = {
    max: 5,
    perspective: 700,
    scale: 1.05,
}

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);


function Example(props){
    AOS.init({
    // initialise with other settings
    duration : 700
  });
    const classes = useStyles();
    
    var items = [
        {
            name: "I AM JOSEPH NGISSAH",
            image: Para0,
            description: "I love to solve problems with code"
        } 
    ]

    const [scrollPosition, setScrollPosition] = React.useState(0);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

 
    useEffect(() => {
        

        return () => {
            handleClickOpen()
        };
    }, [2000] );

    const handleScroll = () => {
        const position = window.pageYOffset;
       
        setScrollPosition(position);
    };

    
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

   

    return (
        
        <div>
        

            <Carousel swipe={true} indicators={false} touch={true} animation={""}>
            {
                items.map( (item, i) => <Item key={i} item={item} /> )
            }
        </Carousel>




    <div>
           
            
            
            
            
                <div>
                   
                    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                            Modal title
                        </DialogTitle>
                        <DialogContent dividers>
                            <form className={classes.root} noValidate autoComplete="off">
                                
                                <TextField id="outlined-basic" style={{margin:5}} label="Outlined" variant="outlined" />
                                <TextField id="outlined-basic" style={{ margin: 5 }} label="Outlined" variant="outlined" />

                            </form>
                        </DialogContent>
                        <DialogActions>
                            <Button autoFocus onClick={handleClose} color="primary">
                                Save changes
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            
            
            
            <div style={{backgroundSize:"cover", paddingTop:20, paddingBottom:50}}>
            
            
            <Container  maxWidth="lg" style={{textAlign:"left", backfroundColor:"rgba(255,255,255,0.1)" , paddingTop:40, paddingBottom:40}}>   
                
                
                
                        <h1 className="" style={{ textAlign: "center", fontWeight:"bolder", fontWeight:"bolder"}} >
                        SCHOOLS' INFORMATION SYSTEM (SIS) REVOLUTION IS THE BEST RESORT FOR <br/> SCHOOL MANAGEMENT                      </h1>
                  <div align="center"><hr data-aos="fade-right" style={{textAlign:"center", width:"46%", marginTop:20, marginBottom:40,backgroundImage:"linear-gradient(300deg, #17dddd 0%,  #1760d3 100%)", border:"none", height:"4px"}}/></div>
<br/>
<div style={{ marginTop:20 }}>
  <Grid container spacing={10}>

            <Grid item lg={7}>
            <p data-aos="fade-up">
            <List dense={true}>
              
              <ListItem style={{marginTop:"-5px"}} data-aos="fade-up">
                <ListItemIcon>
               <DirectionsRunIcon style={{color:"#1760d3", fontSize:"40px"}}/>
                </ListItemIcon>
                <ListItemText
                style={{marginLeft:"-5px"}}
                primary={<h1  style={{fontWeight:"bold", color:"#1760d3"}}>Fast</h1>}

                />
              </ListItem>   
              
              <ListItem style={{marginTop:"-10px"}} data-aos="fade-up">
                <ListItemIcon>
               <MoneyOffIcon style={{color:"#1760d3", fontSize:"40px"}}/>
                </ListItemIcon>
                <ListItemText
                style={{marginLeft:"-5px"}}
                primary={<h1  style={{fontWeight:"bold", color:"#1760d3"}}>Affordable</h1>}
                // secondary={'Secondary text'}
                />
              </ListItem>  
              <ListItem style={{marginTop:"-10px"}} data-aos="fade-up">
                <ListItemIcon>
               <VerifiedUserIcon style={{color:"#1760d3", fontSize:"40px"}}/>
                </ListItemIcon>
                <ListItemText
                style={{marginLeft:"-5px"}}
                primary={<h1 style={{fontWeight:"bold", color:"#1760d3"}}>Reliable</h1>}
                // secondary={'Secondary text'}
                />
              </ListItem> 
             
          
          </List>
Schools' Information System (SIS) Revolution is a school database management system specially built to digitize the activities of schools, with the vision of also customizing the system upon request with terms and conditions applied. We provide free router and monthly data package for shools without internet access. 
<br/>
<br/>
Information Technology has become very essential, it has become the main tool for 
solving major problems we face daily. The relevance of SIS Revolution is dependent on our 
ability to make life easier for schools by providing reliable system for them to manage their 
financial records, student records, staff records, easy access to parents etc.
</p>

            </Grid>
            <Grid item lg={5}>


            {/* <Parallax bgImage={Para0} strength={200} >
      <div style={{ height: 310 }}>

      </div>
    </Parallax> */}

{/* <div> */}

{/* <p style={{color:'gray'}}>Join SIS Revolution today</p>


<List dense={true}>
              
              <ListItem style={{marginTop:"-5px"}} data-aos="fade-up">
                <ListItemIcon>
               <DirectionsRunIcon style={{color:"#1760d3", fontSize:"40px"}}/>
                </ListItemIcon>
                <ListItemText
                style={{marginLeft:"-5px"}}
                primary={<h1  style={{fontWeight:"bold"}}>Fast</h1>}

                />
              </ListItem>   
              
              <ListItem style={{marginTop:"-10px"}} data-aos="fade-up">
                <ListItemIcon>
               <MoneyOffIcon style={{color:"#1760d3", fontSize:"40px"}}/>
                </ListItemIcon>
                <ListItemText
                style={{marginLeft:"-5px"}}
                primary={<h1  style={{fontWeight:"bold"}}>Affordable</h1>}
                // secondary={'Secondary text'}
                />
              </ListItem>  
              <ListItem style={{marginTop:"-10px"}} data-aos="fade-up">
                <ListItemIcon>
               <VerifiedUserIcon style={{color:"#1760d3", fontSize:"40px"}}/>
                </ListItemIcon>
                <ListItemText
                style={{marginLeft:"-5px"}}
                primary={<h1 style={{fontWeight:"bold"}}>Reliable</h1>}
                // secondary={'Secondary text'}
                />
              </ListItem> 
             
          
          </List>
       
</div> */}


<div>
  <br/>
  <br/>
  <br/>
  {/* <Link to="/register"> */}
  <iframe width="100%" height="315" src="https://www.youtube.com/embed/iQRjFIuWpps" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
  {/* </Link> */}
</div>






            </Grid>

  </Grid>
                     
                        </div>



    </Container> 

</div>
<div style={{ paddingTop:50, paddingBottom:30,backgroundImage:"linear-gradient(315deg, #17dddd 0%,  #1760d3 74%)"}}>


<Container  maxWidth="lg" style={{textAlign:"left"}}>
<h2 style={{  color: "white", textAlign: "center", fontWeight: "lighter" , fontWeight:"bolder"}}   data-aos="fade-in">FEATURES OF THE SIS REVOLUTION </h2 >
<div align="center"><hr data-aos="fade-right" style={{textAlign:"center", width:"30%", marginTop:20, marginBottom:40,backgroundImage:"linear-gradient(300deg, #17dddd 0%,  #1760d3 100%)", border:"none", height:"4px"}}/></div>

<br/>
<br/>

<Grid container spacing={4}>
    <Grid item xs={12} lg={6} align="left">
        <h2 style={{ color: "white", marginBottom:10 }} data-aos="fade-up">Administrative System</h2>
        <div style={{ color: "white" }}>
         
            <li data-aos="fade-up">Management of students' personal information</li>
            <li data-aos="fade-up">Management of students' academic records</li>
            <li data-aos="fade-up">Generates automated academic reports</li>
            <li data-aos="fade-up">Management of financial records, including school fees, and other schools expenses</li>
            <li data-aos="fade-up">Mark and keeping attendance records</li>
            <li data-aos="fade-up">Management of Teachers personal data</li>
            <li data-aos="fade-up">Management of Teachers salary records and payslip</li>
            <li data-aos="fade-up">Academic timetable management </li>
            <li data-aos="fade-up">Send academic records to guardians via text messages and email</li>
            <li data-aos="fade-up">Send bulk messages to guardians via email or text messages (sms) </li>
          
        </div>
    </Grid>


    <Grid item xs={12} lg={6}>


    <div style={{textAlign:"center"}}>

<TiltPhaseSix  options={{}}>
                             <TiltPhaseSix
                                 options={options} >
                                 <img src={Image00} data-aos="fade-up" width="70%" alt="" />
                             </TiltPhaseSix>
                         </TiltPhaseSix>
</div>
    </Grid>
</Grid>

<br />
<br />
<br />
<br />
<br />
<Grid container spacing={4}>
    <Grid item xs={12} lg={6}>

       <div style={{textAlign:"center"}}>

       <TiltPhaseSix
                                    options={{}}>
                                    <TiltPhaseSix
                                        options={options}
                                    >
                                        <img data-aos="fade-up" src={Image01} width="70%" alt="" />
                                    </TiltPhaseSix>
                                </TiltPhaseSix>


       </div>

    </Grid>

    <Grid item xs={12} lg={6} align="left">
        <h2 style={{ color: "white" , marginBottom:10}} data-aos="fade-up" >Teachers' Mobile Application</h2>
        <div style={{ color: "white" }}>
         
            <li data-aos="fade-up" >Mark student attendance</li>
            <li data-aos="fade-up" >Management of students academic records</li>
            <li data-aos="fade-up" >Management of students personal information</li>
            <li data-aos="fade-up" >Development of academic timetable </li>
            <li data-aos="fade-up" >Manages Teachers personal information </li>
            <li data-aos="fade-up" >Lesson notes managemenet </li>
            <li data-aos="fade-up" >Send academic records to guardians via text messages and email </li>
            <li data-aos="fade-up" >Send bulk messages to guardians via email or text messages (sms) </li>
          
        </div>
    </Grid>
</Grid>
<br />
<br />
<br />
<br />
<br />
<Grid container spacing={4}>
    <Grid item xs={12} lg={6} align="left">
        <h2 style={{ color: "white", marginBottom:10 }} data-aos="fade-up">Parents' Portal</h2>
        <div style={{ color: "white" }}>
         
            <li data-aos="fade-up">Access Student Academic Records</li>
            <li data-aos="fade-up">Acess Student Bill</li>
            <li data-aos="fade-up">Access School Fees Payment Records</li>
            <li data-aos="fade-up">Receive notification from school and teachers</li>
            <li data-aos="fade-up">Track Class Assignments</li>
          
        </div>
    </Grid>


    <Grid item xs={12} lg={6}>


    <div style={{textAlign:"center"}}>

<TiltPhaseSix  options={{}}>
                             <TiltPhaseSix
                                 options={options} >
                                 <img src={Parents} data-aos="fade-up" width="70%" alt="" />
                             </TiltPhaseSix>
                         </TiltPhaseSix>
</div>
    </Grid>
</Grid>



<br/>

    
    
</Container>
</div>
     
<Container  maxWidth="lg" style={{textAlign:"left", paddingTop:50, paddingBottom:50}}> 
            <h2  style={{textAlign:"Center", fontWeight:"bolder"}} data-aos="fade-in">
                        <b>Better, Faster, Reliable and Affordable</b>
                        <div align="center"><hr data-aos="fade-right" style={{textAlign:"center", width:"12%", marginTop:20, marginBottom:40,backgroundImage:"linear-gradient(300deg, #17dddd 0%,  #1760d3 100%)", border:"none", height:"4px"}}/></div>       
            </h2>
                    <br />
                    
                    
                    <Grid container spacing={0} >

                      
                        <Grid lg={6} md={6} sm={12}>
    <Grid item sm={12} lg={12} md={12}>
    <br/>
<br/>

    <List dense={true} style={{fontSize:"20px"}}>
              
              <ListItem data-aos="fade-up">
                <ListItemIcon>
               <AlldoneIcon style={{color:"#1760d3"}}/>
                </ListItemIcon>
                <ListItemText
                style={{marginLeft:"-20px"}}
                  primary="Reduce the time it takes to make a decisions in your school"
                  // secondary={'Secondary text'}
                />
              </ListItem>   
              
                 <ListItem data-aos="fade-up">
                <ListItemIcon>
               <AlldoneIcon style={{color:"#1760d3"}}/>
                </ListItemIcon>
                <ListItemText
                style={{marginLeft:"-20px"}}
                  primary="Save money while you get the best services for your school"
                  // secondary={'Secondary text'}
                />
              </ListItem>   
              
                <ListItem data-aos="fade-up">
                <ListItemIcon>
               <AlldoneIcon style={{color:"#1760d3"}}/>
                </ListItemIcon>
                <ListItemText
                style={{marginLeft:"-20px"}}
                  primary="Provides stakeholders with a clear graphical reports"
                  // secondary={'Secondary text'}
                />
              </ListItem>   
              
             
                <ListItem data-aos="fade-up">
                <ListItemIcon>
               <AlldoneIcon style={{color:"#1760d3"}}/>
                </ListItemIcon>
                <ListItemText
                style={{marginLeft:"-20px"}}
                  primary=" Profile your school data with data quality tools"
                  // secondary={'Secondary text'}
                />
              </ListItem>   
              
             
                <ListItem data-aos="fade-up">
                <ListItemIcon>
               <AlldoneIcon style={{color:"#1760d3"}}/>
                </ListItemIcon>
                <ListItemText
                style={{marginLeft:"-20px"}}
                  primary="The best security tools are rendered"
                  // secondary={'Secondary text'}
                />
              </ListItem>   
              
            
          
          </List>
       
    </Grid>




                        </Grid>

                        <Grid lg={6} md={6} sm={12} style={{ padding: 10, paddingTop:50 }} data-aos="fade-up" >

                            <div id="App" data-aos="fade-up" align="center">
                                <TiltPhaseSix
                                    options={{}}

                                >
                                    <TiltPhaseSix
                                        options={options}
                                    >
                                        <img src={Image02} width="70%" alt="" />
                                    </TiltPhaseSix>
                                </TiltPhaseSix>
                            </div>


                            




                        </Grid>



    
    
</Grid>




    </Container> 

<br/>
{/* <div>
<Divider  data-aos="zoom-in"/>
<br/>
<br/>
<br/>

<h2 className="MuiTypography-h5" style={{textAlign:"Center", marginBottom:"-40px"}} data-aos="fade-in">
                        <b>Trusted By</b>
                        <div align="center"><hr data-aos="fade-right" style={{textAlign:"center", width:"8%", marginTop:20, marginBottom:40,backgroundImage:"linear-gradient(300deg, #17dddd 0%,  #1760d3 100%)", border:"none", height:"4px"}}/></div>

                       
            </h2>
<br/>
<br/>
<br/>
<br/>
            
            <Carousel_ 
             swipeable={false}
             draggable={false}
             autoPlaySpeed={500}
             customTransition="all .5"
             transitionDuration={500}
            responsive={responsive}>
  <div>Item 1</div>
  <div>Item 2</div>
  <div>Item 3</div>
  <div>Item 4</div>
  <div>Item 4</div>
  <div>Item 4</div>
  <div>Item 4</div>
  <div>Item 4</div>
</Carousel_>;

</div>
     */}
    </div>




      <br/>
<br/>
<br/>
<br/>

    </div>

      
    )
}

function Item(props)



{
    const classes=useStyles()
    return (
        
        
<div>

<div style={{position:"absolute", zIndex:1, width:"100%", height:"50px"}}>
<Grid container spacing={5} >
<Grid item  xs={12} sm={12} lg={6} md={6} style={{ marginTop:"2%"}}>

                       
                        <div id="App"  data-aos={"flip-right"}   style={{ color: "white", fontSize: "33px", marginTop: 37, position: "relative", zIndex: 1 }}>
                            <TiltPhaseSix
                                options={{}}

                            >
                                <TiltPhaseSix
                                    options={options}
                                >
                                    <img src={User} className="imager" alt="" />
                                </TiltPhaseSix>
                            </TiltPhaseSix>

                           

                           

                            {/* <Button style={{ backgroundImage: "linear-gradient(315deg, #ffb700 0%,  #1760d3 74%)", color: "white", zIndex: 1 }} className={classes.smaller}>
                                Download CV
                            </Button> */}
                        </div>
                        

</Grid>

<Grid item  xs={12} sm={12} lg={5} md={6}  >

<div style={{ position: "relative", zIndex: 1, marginTop: "20%",}}>

<div align="center"><hr data-aos="fade-right" style={{textAlign:"center", width:"85%", marginTop:"-20px", marginBottom:10,backgroundImage:"linear-gradient(300deg, #17dddd 0%,  #1760d3 100%)", border:"none", height:"4px"}}/></div>

<h1 data-aos={"flip-left"} className={classes.smaller} style={{color:"white", fontWeight:"bolder"}}>
    SIS REVOLUTION <br/>
  <small style={{fontSize:"20px", marginTop:"-25px"}}>Revolutionizing The Education Systems</small>

</h1>
<p data-aos={"flip-left"} className={classes.larger} style={{color:"white", fontWeight:"bolder"}}>
  <small style={{fontSize:"16px", marginTop:"-25px"}}>Revolutionizing The Education Systems</small>

<br/>

</p>
<Link  to="/register"><Button  className={classes.smaller} style={{margin:10, padding:15, backgroundImage:"linear-gradient(300deg, #17dddd 0%,  #1760d3 100%)", color:"white" }}>Get The Software Now</Button> </Link>
<div align="center"><hr data-aos="fade-left" style={{textAlign:"center", width:"85%", marginTop:10, marginBottom:40,backgroundImage:"linear-gradient(300deg, #17dddd 0%,  #1760d3 100%)", border:"none", height:"4px"}}/></div>


                     
</div>
</Grid>

</Grid>


          
</div>





<div className="particle" >
<Particles  
        id="tsparticle"
        options={{
          background: {
            color: {
              value: "red",
            },
        
          },

          fpsLimit: 30,
          interactivity: {
            detectsOn: "canvas",
            events: {
              onClick: {
                enable: true,
                mode: "push",
              },
              onHover: {
                enable: false,
                mode: "repulse",
              },
              resize: true,
            },
            modes: {
              bubble: {
                distance: 400,
                duration: 0.5,
                opacity: 0.8,
                size: 40,
              },
              push: {
                quantity: 10,
              },
              repulse: {
                distance: 200,
                duration: 0.4,
              },
            },
          },
            particles: {
                color: {
                    value: ["#1760d3", "#1760d3", "#1760d3"]
                },
                links: {
                    color: "#1760d3",
                    distance: 150,
                    enable: true,
                    opacity: 0.2,
                    width: 0.8,
                },
                collisions: {
                    enable: false,
                },
                move: {
                    direction: "none",
                    enable: true,
                    outMode: "bounce",
                    random: true,
                    speed: 3,
                    straight: false,
                },
                number: {
                    density: {
                        enable: true,
                        value_area: 900,
                    },
                    value: 100,
                },
                opacity: {
                    value: 0.2,
                },
                shape: {
                    type: "circle",
                },
                size: {
                    random: true,
                    value: 5,
                },
            },
          detectRetina: true,
        }}


      />


</div>
   </div>  

    )
}


export default Example



import React from 'react';
import Carousel from 'react-material-ui-carousel';
import { Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Para0 from './img/code/HD-wallpaper-code-programming-monitor-hacker.jpg';
import Use2 from './img/use2.jpg';

import AOS from 'aos';
import "./index1.css";
import Particles from 'react-tsparticles'
import "aos/dist/aos.css";
import Cvmain from "./cv_joseph.pdf"




function Example(props) {
    AOS.init({
        // initialise with other settings
        duration: 700
    });




    return (


        <div id="styler2" >
            <Container maxWidth="lg" className="styler2" style={{ textAlign: "left", paddingTop: 50, paddingBottom: 50 }}>
                

                <Grid container spacing={0}>





                    <Grid lg={6} md={6} sm={6} style={{marginTop:30}} >


                        <div class="container">
                            <div class="box">
                                <div class="imgBox" >
                                    <img style={{ width: "100%" }} src={Use2} alt="" />
                                </div>
                                <div class="content" >
                                <h3> Website for USA based 3AM Mission Ministry <br /><p>
                                        <a href="https://3ammissionministry.com" target="_blank">
                                            Visit
                                        </a>
                                        <br />
                                        <br />
                                    </p> </h3>
                                </div>
                            </div>



                        </div>


                    </Grid>


                    <Grid lg={6} md={6} sm={6} style={{ marginTop: 30 }} >


                        <div class="container">
                            <div class="box">
                                <div class="imgBox" >
                                    <img style={{ width: "100%" }} src={Use2} width="100%" alt="" />
                                </div>
                                <div class="content" >
                                <h3> Online Shop for Chinawholesale Buying Group <br /><p>
                                        <a href="https://chinawholesalebuying.com" target="_blank">
                                            Visit
                                        </a>
                                        <br />
                                        <br />
                                    </p> </h3>
                                </div>
                            </div>



                        </div>


                    </Grid>



                    <Grid lg={6} md={6} sm={6} style={{ marginTop: 30 }} >


                        <div class="container">
                            <div class="box">
                                <div class="imgBox" >
                                    <img style={{ width: "100%" }} src={Use2} width="100%" alt="" />
                                </div>
                                <div class="content" >
                                <h3>Website for ZF1 Garden Logistics <br /><p>
                                        <a href="https://zf1logistics.com" target="_blank">
                                            Visit
                                        </a>
                                        <br />
                                        <br />
                                    </p> </h3>
                                </div>
                            </div>



                        </div>


                    </Grid>




             



                    <Grid lg={6} md={6} sm={6} style={{ marginTop: 30 }} >


                        <div class="container">
                            <div class="box">
                                <div class="imgBox" >
                                    <img style={{ width: "100%" }} src={Use2} width="100%" alt="" />
                                </div>
                                <div class="content" >
                                <h3>Databased management System for AY Insurance  <br /><p>
                                        <a href="https://app.ayconsultgh.com" target="_blank">
                                            Visit
                                        </a>
                                        <br />
                                        <br />
                                    </p> </h3>
                                </div>
                            </div>



                        </div>


                    </Grid>




                    <Grid lg={6} md={6} sm={6} style={{ marginTop: 30 }} >


                        <div class="container">
                            <div class="box">
                                <div class="imgBox" >
                                    <img style={{ width: "100%" }} src={Use2} width="100%" alt="" />
                                </div>
                                <div class="content" >
                                <h3>Website for Heralds World Ministries  <br /><p>
                                        <a href="https://ravinlead.com/hwm" target="_blank">
                                            Visit
                                        </a>
                                        <br />
                                        <br />
                                    </p> </h3>
                                </div>
                            </div>



                        </div>


                    </Grid>





                    <Grid lg={6} md={6} sm={6} style={{ marginTop: 30 }} >


                        <div class="container">
                            <div class="box">
                                <div class="imgBox" >
                                    <img style={{ width: "100%" }} src={Use2} width="100%" alt="" />
                                </div>
                                <div class="content" >
                                <h3>Website for GCCI  <br /><p>
                                        <a href="https://ravinlead.com/gcci" target="_blank">
                                            Visit
                                        </a>
                                        <br />
                                        <br />
                                    </p> </h3>
                                </div>
                            </div>



                        </div>


                    </Grid>




                    <Grid lg={6} md={6} sm={6} style={{ marginTop: 30 }} >


                        <div class="container">
                            <div class="box">
                                <div class="imgBox" >
                                    <img style={{ width: "100%" }} src={Use2} width="100%" alt="" />
                                </div>
                                <div class="content" >
                                <h3>Website for Pax Global Company  <br /><p>
                                        <a href="https://ravinlead.com/vipax" target="_blank">
                                            Visit
                                        </a>
                                        <br />
                                        <br />
                                    </p> </h3>
                                </div>
                            </div>



                        </div>


                    </Grid>





                    <Grid lg={6} md={6} sm={6} style={{ marginTop: 30 }} >


                        <div class="container">
                            <div class="box">
                                <div class="imgBox" >
                                    <img style={{ width: "100%" }} src={Use2} width="100%" alt="" />
                                </div>
                                <div class="content" >
                                <h3>Website for Emador Company <br /><p>
                                        <a href="https://ravinlead.com/emador" target="_blank">
                                            Visit
                                        </a>
                                        <br />
                                        <br />
                                    </p> </h3>
                                </div>
                            </div>



                        </div>


                    </Grid>



                    <Grid lg={6} md={6} sm={6} style={{ marginTop: 30 }} >


                        <div class="container">
                            <div class="box">
                                <div class="imgBox" >
                                    <img style={{ width: "100%" }} src={Use2} width="100%" alt="" />
                                </div>
                                <div class="content" >
                                <h3>Website for Atiefoo Express <br /><p>
                                        <a href="https://ravinlead.com/atiefoo" target="_blank">
                                            Visit
                                        </a>
                                        <br />
                                        <br />
                                    </p> </h3>
                                </div>
                            </div>



                        </div>


                    </Grid>





                </Grid>





            </Container>
        </div>



    )
}


export default Example


